

export default ({redirect}) => {

    return (
        <footer>
            <div>
                <h3>Soluciones</h3>
                <p>Construimos soluciones basadas en conocimiento, innovación y tecnología.</p>
                <div className="social">
                    <a target="_blank" href="https://www.facebook.com/sanolivar">
                        <img src="https://img.icons8.com/?size=48&id=59780&format=png&color=ffffff" alt="Icono Facebook" />
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/sanolivar">
                        <img src="https://img.icons8.com/?size=48&id=8808&format=png&color=ffffff" alt="Icono LinkedIn" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/sanolivar">
                        <img src="https://img.icons8.com/?size=48&id=85140&format=png&color=ffffff" alt="Icono Instagram" />
                    </a>
                    <a target="_blank" href="https://www.youtube.com/channel/UCY_6_D1hR2BbXgE5UqcEesA">
                        <img src="https://img.icons8.com/?size=48&id=37326&format=png&color=ffffff" alt="Icono YouTube" />
                    </a>
                </div>
            </div>
            <div>
                <h3>Contáctanos</h3>
                <table>
                    <tbody>
                        <tr><td>Dirección Bogotá:</td><td>Ak 68 #75A-50 Of 402</td></tr>
                        <tr><td>Dirección Tenjo:</td><td>Cra. 12 # 3-57</td></tr>
                        <tr><td>Teléfono:</td><td>(+57) 601 9514389</td></tr>
                        <tr><td>Whatsapp:</td><td>(+57) 313 2514711</td></tr>
                        <tr><td>Whatsapp:</td><td>(+57) 350 6506072</td></tr>
                    </tbody>
                </table>
            </div>
            <div>
                <h3>Solicitar Contacto</h3>
                <form action="https://sanolivar.com/api/public/contacto-sitio.php" method="post">
                    <input style={{ width: '90%' }} type="email" name="email-contacto" id="email-contacto" placeholder="Ingrese su correo electrónico" />
                    <input style={{ display: 'none'}} type="text" name="nombre" id="nombre-footer" defaultValue={'Anonimo - Footer'} />
                    <input name="redirect" style={{ display: 'none' }} type="text" defaultValue={window.location.href} />
                    <button id="footer-submit" name="footer-submit">Solicitar Contacto</button>
                </form>
            </div>
        </footer>
    )
}
import { useEffect, useRef, useState } from "react";

import { root } from "../utils/Path";
import { Dialog } from "./PopUp";


export default ({ active }) => {

    const [collapsed, setCollapsed] = useState(true);
    const [redir, setRedir] = useState(undefined);
    const ref = useRef(null);

    const links = {
        'Inicio': `${root}index.html`,
        'Nosotros': `${root}nosotros.html`,
        'Innovación & Servicios':{ 
            link: `${root}innovacion.html`,
            content: [
                {
                    text: "I.A.",
                    link: `${root}servicios-ia.html`,
                },
                {
                    text: "IoT",
                    link: `${root}servicios-iot.html`,
                },
                {
                    text: "Location Services",
                    link: `${root}servicios-locationservices.html`,
                },
                {
                    text: "Servicio de Gestión",
                    link: `${root}servicios-gestion.html`,
                },
            ]
        },
        'Infraestructura Tecnológica': `${root}infraestructura.html`,
        'Datacenters': {
            link: '#',
            content: [
                { 
                    text: 'Cómputo', 
                    link: `${root}computo.html`,
                }, { 
                    text: 'Networking', 
                    link: `${root}networking.html`,
                }
            ],
        },
        'Clientes': `${root}nosotros.html#clientes`,
        'Contacto': '#contacto'
    }

    useEffect(() => {
        // collapse the navigation menu when the user left the element
        if (ref.current) {
            ref.current.addEventListener('mouseleave', e => {
                setCollapsed(true);
            })
        }
        // check for redirections
        //redirect = true message = ??
        let params = window.location.search;
        if(params.length == 0) return;
        // clear history
        window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
        params = new URLSearchParams(params)
        setRedir({
            title: params.get('title'),
            message: params.get('message'),
            btn: 'Aceptar'
        });
    }, [])

    return (
        <header>
            <a href="/index.html" role="link" name="Titulo Principal">Sanolivar | Innovation, Technology & Services</a>
            <nav ref={ref}>
                <span className={`burger ${collapsed ? '' : 'shown'}`} onClick={e => setCollapsed(!collapsed)}>
                    <i></i>
                    <i></i>
                    <i></i>
                </span>
                <ul role="menu" aria-label="Menu de navegación">
                    {
                        Object.keys(links).map(lnk => (
                            <li key={`li-${lnk}`} role="none">
                                {
                                    typeof (links[lnk]) != 'object' ?
                                        (<a className={lnk == active ? 'active' : ''} key={`a-${lnk}`} role="menuitem" aria-label={`Ir a sección ${lnk}`} href={links[lnk]}>{lnk}</a>) :
                                        (<span className={lnk == active ? 'active' : ''} key={`a-${lnk}`} role="button" tabIndex={0} aria-haspopup='true' href={links[lnk].link} onClick={e=>{
                                            window.location.replace(links[lnk].link)
                                        }}>
                                            {lnk}
                                            <ul role="menu" aria-label={`Submenú de ${lnk}`}>
                                                {
                                                    links[lnk].content.map(ln => (
                                                        <li role="none" key={`li-${ln.text}`}><a aria-label={`Ir a sección ${ln.text}`} key={`a-${ln.text}`} href={ln.link}>{ln.text}</a></li>
                                                    ))
                                                }
                                            </ul>
                                        </span>)
                                }
                            </li>
                        ))
                    }
                </ul>
            </nav>
            {
                redir == undefined ? (<></>) :
                (<Dialog title={redir.title} message={redir.message} btnText={redir.btn} callback={e=> setRedir(undefined)} />)
            }
        </header>
    )
}


export default ({ title, content, action, img, form, redirect }) => {
    return (
        <section className="compressed form" id="contacto">
            <h2 data-aos="fade-up">{title}</h2>
            <p data-aos="fade-up" className="centered stretch">{content}</p>
            <div>
                <form action={"https://sanolivar.com/api/public/contacto-sitio.php"} method="post" data-aos="fade-right">
                    {
                        form.map((field, index) => (
                            field.type == 'textarea' ? (
                                <div className="inverted" key={field.id + '-parentdiv' + index}>
                                    <textarea id={field.id} name={field.id + index} type={field.type} placeholder={field.placeholder} ></textarea>
                                    <label htmlFor={field.id}>{field.label}</label>
                                </div>
                            ) : field.type == 'submit' ? (
                                <button id={field.id} name={field.id} key={field.id}>{field.label}</button>
                            ) : (
                                <div className="inverted" key={field.id + '-parentdiv' + index}>
                                    <input id={field.id} name={field.id} type={field.type} placeholder={field.placeholder} />
                                    <label htmlFor={field.id}>{field.label}</label>
                                </div>
                            )
                        ))
                    }
                    <input name="redirect" style={{ display: 'none' }} type="text" defaultValue={redirect} />
                </form>
                <img src={img.src} alt={img.alt} aria-label={img.alt} title={img.alt} data-aos="fade-left" />
            </div>
        </section>
    )
}